

















import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  ListingFilter,
  ListingOrderBy,
} from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';
import {
  ListingCategory,
  ListingStatus,
  MethodOfSale,
  PropertyCategory,
  PropertyType,
} from 'client-website-ts-library/types';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class PropertiesForRent extends Mixins(View) {
  public listingFilter: ListingFilter | null = null;

  mounted() {
    this.updateFilter();
  }

  @Watch('$route.query')
  updateFilter() {
    let propertyCategories: PropertyCategory[] = [];
    let propertyTypes: PropertyType[] = [];

    if (this.$route.query.ListingCategory) {
      propertyCategories = (this.$route.query.ListingCategory! as string)
        .split(',')
        // eslint-disable-next-line radix
        .map((v) => parseInt(v) as PropertyCategory);
    }

    if (this.$route.query.propertyTypes) {
      propertyTypes = (this.$route.query.propertyTypes! as string)
        .split('|')
        // eslint-disable-next-line radix
        .map((v) => parseInt(v) as PropertyType);
    }

    const filter = new ListingFilter({
      PropertyCategories: propertyCategories,
      PropertyTypes: propertyTypes,
      Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
      MethodsOfSale: [MethodOfSale.Lease],
      Categories: [ListingCategory.ResidentialRental],
      PageSize: 9,
      OrderByStatements: [ListingOrderBy.CreateDateDesc],
    });

    if (this.$route.query.keywordOptions) {
      const keywordOpts = (this.$route.query.keywordOptions as string)
        .split('|')
        .map((opt) => {
          const split = opt.split(':');

          return {
            Type: split[0],
            Value: split[1],
          };
        });

      filter.Suburbs = keywordOpts
        .filter((opt) => opt.Type === 'suburb')
        .map((opt) => opt.Value);

      const categories = keywordOpts
        .filter((opt) => opt.Type === 'categories')
        .map((opt) => parseInt(opt.Value, 10));

      if (categories.length > 0) {
        filter.PropertyCategories = categories;
      }

      const searchVal = keywordOpts.find((opt) => opt.Type === 'search');

      if (searchVal) {
        filter.SearchTerm = searchVal.Value;
      }
    }

    if (this.$route.query.areaMin) {
      filter.MinLandArea = this.$route.query.areaMin as string;
    }

    if (this.$route.query.areaMax) {
      filter.MaxLandArea = this.$route.query.areaMax as string;
    }

    if (this.$route.query.priceMin) {
      filter.MinPrice = this.$route.query.priceMin as string;
    }

    if (this.$route.query.priceMax) {
      filter.MaxPrice = this.$route.query.priceMax as string;
    }

    if (this.$route.query.suburbs) {
      filter.Suburbs = (this.$route.query.suburbs as string).split('|');
    }

    if (this.$route.query.isVacantPosession) filter.IsVacant = true;
    if (this.$route.query.isTenanted) filter.IsTenanted = true;
    if (this.$route.query.isStandalone) filter.IsWholeBuilding = true;
    if (this.$route.query.isStrata) filter.IsVacant = true;

    this.listingFilter = filter;
  }
}
